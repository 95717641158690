<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form  @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0"> {{module_name}}</h3> 
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div>
                                <div class="grid">
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Employee ID</label>
                                        <InputText id="emp_id" placeholder="Employee ID" v-model="form.emp_id"  type="text" class="full-width"/>
                                        <HasError class="p-error" :form="form" field="emp_id" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Name</label>
                                        <InputText v-model="form.name" id="name" type="text" placeholder="Name"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="name" /> 
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>CNIC</label>
                                        <InputText type="text" id="cnic" v-model="form.cnic" class="full-width"  placeholder="CNIC" />
                                        <HasError class="p-error" :form="form" field="cnic" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Email</label>
                                        <InputText type="text" id="email" v-model="form.email" class="full-width"  placeholder="Email" />
                                        <HasError class="p-error" :form="form" field="email" /> 
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Phone</label>
                                        <InputText type="text" id="phone" v-model="form.phone" class="full-width"  placeholder="Phone" />
                                        <HasError class="p-error" :form="form" field="phone" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Password</label>
                                        <InputText type="text" id="password" v-model="form.password" class="full-width"  placeholder="Password" />
                                        <HasError class="p-error" :form="form" field="password" /> 
                                    </div>
                                </div>
                                <div class="grid">
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Department</label>
                                        <Dropdown :filter="true" v-model="form.department" optionValue="id" :options="departments" optionLabel="name" placeholder="Select a Department"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="department" /> 
                                    </div>
                                    <div class="col-12 lg:col-6 sm:col-12 mb-2"> 
                                        <label>Designation</label>
                                        <Dropdown :filter="true" v-model="form.designation" optionValue="id" :options="designations" optionLabel="name" placeholder="Select a Designation"  class="full-width"  />
                                        <HasError class="p-error" :form="form" field="designation" /> 
                                    </div>
                                </div>
                                
                            </div>  
                            

                        </div> 
                        
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <!-- <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/> -->
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    <ConfirmDialog group="dialog" />
</template>
<style scoped>
    .active-room{
        background-color: aquamarine !important;
    }
</style>
<script> 
import Dropdown from 'primevue/dropdown';
export default {
    data() {
			return {  
                form: new this.Form({ 
                    emp_id: '', 
                    name: '', 
                    cnic:'',  
                    email:'',
                    phone:'',  
                    password:'',
                    department:'',
                    designation:'',
                }),   
              
                module_name:'User',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                
                
                departments:[],
                designations:[],
             
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            selectedData:{
                type : String,
            }
        },
		created() {  
           
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.getAllRequestData();  
            this.formVisible = this.showForm; 
            this.recId = this.recordId; 
            this.selectedData ? this.form.fill(JSON.parse(this.selectedData)) : null;
            this.form.password = '';
		},
		methods: {   
            getAllRequestData(){   
                this.$axios.all([this.getAllDepartments(),this.getAllDesignations()])   
                .then(() => {  
                    // this.viewRecord();   
                }) 
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {    
                //    this.getAllRequestData();
                })
                .finally(() => {  
                }); 
            },
           
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                this.form.id = this.recId;
                await this.form.post(Vthis.$baseurl+'api/saveUser')
                .then(() => { 
                    if(this.saveAndClose){   
                        this.saveAndClose = false;
                        this.closeForm();
                    }   
                    Vthis.showMessage('Saved successfully','success',); 
                })
                .catch((error) => {   
                    let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
                    Vthis.showMessage(message,'error'); 
                })
                .finally(() => {
                    this.isSaving = false; 
                    this.getAllRequestData();
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
             
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getActiveRoom',{std_id:this.recId})
                    .then( (response) => {    
                        //Vthis.form.fill(response.data.activeRoom[0]);
                        Vthis.hostels = response.data.hostels;
                    })
                    .catch((error) => {    
                        let message = Vthis.customError(error); 
                        Vthis.showMessage(message,'error'); 
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },  
            async viewRecord(){ 
                var Vthis = this;
                await this.axios.post('getAllHosteliseStudents',{id:this.recId})
                .then( (response) => {  
                    this.data = response.data[0]; 
                }).catch((error) => {    
                    let message = Vthis.customError(error.response); 
                    Vthis.showMessage(message,'error');  
                }).finally(() => { 
                })  
            }, 
            async getAllDepartments(){
                var Vthis = this;
                await this.axios.post('getAllDepartments')
                .then( (response) => {  
                    this.departments = response.data;  
                }).catch((error) => {    
                    let message = Vthis.customError(error.response); 
                    Vthis.showMessage(message,'error');  
                }).finally(() => { 
                }) 
            },
            async getAllDesignations(){
                var Vthis = this;
                await this.axios.post('getAllDesignations')
                .then( (response) => {  
                    this.designations = response.data;  
                }).catch((error) => {    
                    let message = Vthis.customError(error.response); 
                    Vthis.showMessage(message,'error');  
                }).finally(() => { 
                }) 
            },
            deleteRecord(roomId){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        Vthis.loading = true;  
                        Vthis.axios.post('deleteRoom',{id:roomId})
                        .then( () => {   
                            Vthis.getAllRequestData();
                            Vthis.showMessage('Record deleted successfully','success'); 
                        })
                        .catch((error) => {   
                            let message = Vthis.customError(error.response ); 
                            Vthis.showMessage(message,'error'); 
                        })
                        .finally(() => { 
                            Vthis.loading = false;
                        })
                    }, reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
            
		},
        watch : {
             
        }
}
</script>
 
