<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
           
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0"> {{module_name}}</h3> 
                    </div>
                    <Divider class="mt-0" />
                    <TabView  @tab-click="loadTargetedTab($event)" class="tabview-custom" v-model:activeIndex="activeTab" :scrollable="true">
                         <TabPanel header="User Information">
                            <template #header>
                                &nbsp; <i class="pi pi-info-circle"></i>
                            </template>
                            <div class="p-panel p-component col-12 lg:col-12 sm:col-12" style="float: left;"> 
                                <div class="p-toggleable-content" role="region" id="pv_id_6_content" aria-labelledby="pv_id_6_header">
                                    <div class="p-panel-content">
                                        <div class="col-12 lg:col-2 sm:col-2 mb-2 profileCardImg" style="float: left;">
                                            <div class="mb-2">
                                            <img   :alt="data.name" :src="filesUrl+'defaults/default.png'" style="width:180px; height: 180px;" />
                                            </div>
                                            <div>
                                                <span class="p-tag p-component p-tag-success p-tag-rounded mb-2 span-100 font-14 mb-2 span-100 font-14">
                                                    <!----><span class="p-tag-value">{{data.status}}</span>
                                                </span>
                                                <span class="p-tag p-component p-tag-warning p-tag-rounded mb-2 span-100 font-14 mb-2 span-100 font-14">
                                                    <!----><span class="p-tag-value">Employee ID: {{data.emp_id}}</span>
                                                </span>
                                                </div>
                                        </div>
                                        <div class="col-12 lg:col-10 sm:col-9 mb-3" style="float: left;">
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Name: &nbsp;</b><span>{{data.name}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Email: &nbsp;</b><span>{{data.email}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Phone: &nbsp;</b><span>{{data.phone}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>CNIC: &nbsp;</b><span>{{data.cnic}}</span></div>

                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Department: &nbsp;</b><span>{{data.departmentName}}</span></div>
                                            <div class="col-12 lg:col-5 sm:col-5 mb-2 " style="float: left;"><b>Designation: &nbsp;</b><span>{{data.designationName}}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>


                    </TabView>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
        
        </Sidebar> 
    </BlockUI>
    
</template>
<script> 
import {FilterMatchMode,FilterOperator} from 'primevue/api';  
export default {
    data() {
			return {  
                module_name:'User Information',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                columns: null,
                filters: null,
                loading: true,
			}
		},   
        props:{
            showView : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            selectedData:{
                type : String,
            }
        },
		created() {   
            this.initFilters();
            this.columns = [
            ];
            this.selectedColumns = this.columns; 
		},
        computed: { 

        },
		mounted() {  
            this.formVisible = this.showView; 
            this.recId = this.recordId;  
            this.selectedData ?  this.data = JSON.parse(this.selectedData) : null;
		},
		methods: {  
             loadTargetedTab($event){
                this.showTab1 = false;
                this.showTab2 = false;
                let i = 1;
                do { 
                    if($event.index === 1){
                        this.showTab1 = true;
                    }
                    if($event.index === 2){
                        this.showTab2 = true;
                    }
                    i += 1;
                } while (i < 6);
            },  
            closeForm(){
                 this.$emit('formClose',true); 
            },
            initFilters() {
				this.filters = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				}
			},
			clearFilter() {
				this.initFilters();
			}, 
		},
        watch : {
             
        }
}
</script>
 
